export default {
    methods: {
        getAvailableProductsRange(program, includeWithoutRooms) {
            let text = '';
            if (program.dispos.length > 0) {
                // Appartement / Maison avec pièces
                const min = program.minRoomsLabel ? program.minRoomsLabel : '';
                const max = program.maxRoomsLabel ? program.maxRoomsLabel : '';
                if (min && max) {
                    if (min === max) {
                        text = min;
                    } else {
                        text = `De ${min} à ${max}`;
                    }
                }
            } else {
                text = 'Aucun lot disponible';
            }

            if (includeWithoutRooms) {
                // Terrain et autres sans pièce
                let map = {};
                for (const dispo of program.dispos) {
                    if (dispo.rooms) {
                        continue;
                    }
                    if (!map.hasOwnProperty(dispo.typeLabel)) {
                        map[dispo.typeLabel] = 1;
                    } else {
                        map[dispo.typeLabel]++;
                    }
                }
                const types = Object.keys(map).join('<br>');
                if (text.length > 0 && types.length > 0) {
                    text += '<br>';
                }
                text += types;
            }

            return text;
        }
    }
};
