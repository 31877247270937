<template>
    <div style="display: flex; flex-flow: column; height: 100%;">
        <v-container ref="searchContainer">
            <v-row>
                <v-col class="pt-0">
                    <h2 class="primary--text"> Programmes </h2>
                </v-col>
            </v-row>

            <!-- Quick search and pagination -->
            <v-row class="pb-4" align="center" no-gutters>
                <v-col cols="12" md="2" class="mb-2 mb-md-0">
                    <v-text-field v-model="quickSearch" @keydown.enter="fetchPrograms(true)" label="Ville, CP, Programme..." dense hide-details outlined clearable>
                        <template v-slot:append>
                            <v-icon color="grey" @click="fetchPrograms(true)"> fas fa-search </v-icon>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                    <v-btn class="mr-4 ml-0 ml-md-4 mb-2 mb-md-0" @click="displayAdvanceSearch = !displayAdvanceSearch" color="primary" outlined depressed small>
                        <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                        <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                        de filtres
                    </v-btn>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn small text class="grey lighten-2 mr-4 mb-2 mb-md-0" v-on="on">
                                {{ programsCount }}
                            </v-btn>
                        </template>
                        Nombre total de programmes
                    </v-tooltip>

                    <v-item-group v-model="view" class="d-inline-block mb-2 mb-md-0" mandatory>
                        <v-item :value="'cards'" v-slot="{ active, toggle }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="toggle" icon v-on="on">
                                        <v-icon :color="active ? 'primary' : 'grey'"> fas fa-th </v-icon>
                                    </v-btn>
                                </template>
                                Voir les programmes en liste
                            </v-tooltip>
                        </v-item>

                        <v-item :value="'map'" class="mx-4" v-slot="{ active, toggle }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="toggle" icon v-on="on">
                                        <v-icon :color="active ? 'primary' : 'grey'"> fas fa-map-marked </v-icon>
                                    </v-btn>
                                </template>
                                Voir les programmes sur une carte
                            </v-tooltip>
                        </v-item>
                    </v-item-group>
                </v-col>

                <v-col cols="12" md="5" class="text-right">
                    <v-select class="sort-pagination d-inline-flex" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="programSortItems" background-color="white" @change="fetchPrograms(true)" hide-details outlined dense>
                        <template v-slot:append-outer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                        <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                            fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <template v-if="by === 'asc'">
                                    Croissant
                                </template>
                                <template v-if="by === 'desc'">
                                    Décroissant
                                </template>
                            </v-tooltip>
                        </template>
                    </v-select>

                    <v-pagination v-model="page" v-if="getConfig('programs.map_list_limit') !== 0" @input="fetchPrograms(true, false)" :length="totalPages" :total-visible="7" class="pagination d-inline-block" color="primary" />
                </v-col>
            </v-row>

            <!-- Advance search -->
            <v-expand-transition>
                <v-card v-show="displayAdvanceSearch" elevation="0">
                    <v-sheet color="grey lighten-3" class="pa-4 mb-4">
                        <span class="caption grey--text text--darken-1"> Programme </span>
                        <v-row class="mb-2">
                            <v-col cols="3">
                                <ProgramLocationSelect v-model="locationsFilter" :places="places" label="Localisation" />
                            </v-col>

                            <v-col cols="3">
                                <ProgramAutocomplete ref="programAutocomplete" v-model="programsFilter" hideArchived multiple label="Programmes" />
                            </v-col>

                            <v-col cols="3">
                                <ProgramFiscaliteSelect v-model="fiscalitesFilter" label="Fiscalités" multiple />
                            </v-col>

                            <v-col cols="3">
                                <DatePickerMenu v-model="beforeDeliveryDateFilter" endOfMonth label="Date de Livraison" type="month" />
                            </v-col>
                        </v-row>

                        <span class="caption grey--text text--darken-1"> Lots </span>
                        <v-row>
                            <v-col cols="3">
                                <ProductTypeSelect v-model="productTypesFilter" label="Nature" multiple />
                            </v-col>

                            <v-col cols="3">
                                <ProductRoomsSelect v-model="productRoomsFilter" label="Typologie" multiple />
                            </v-col>

                            <v-col v-if="getParameter('PRG_TYPERESIDENCE')" cols="3">
                                <TypeResidenceSelect v-model="residenceTypesFilter" label="Type de résidence" multiple />
                            </v-col>

                            <v-col cols="3">
                                <v-range-slider label="Budget (k€)" v-model="productBudgetFilter" :thumb-size="35" thumb-label="always" min="0" max="1000" step="10" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary" class="mr-4" dark depressed small @click="fetchPrograms(true)">
                                    <v-icon left x-small> fas fa-search </v-icon>
                                    Rechercher
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card>
            </v-expand-transition>
        </v-container>

        <v-fade-transition>
            <v-container v-show="view === 'cards'">
                <v-row v-if="view === 'cards'">
                    <v-col v-for="program of programs" :key="program.id" cols="12" sm="6" xl="3">
                        <router-link :to="`/programmes/${program.id}`" style="text-decoration: none;">
                            <ProgramCard :program="program" />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </v-fade-transition>

        <v-fade-transition>
            <v-container v-show="view === 'map'" style="max-height: 100%">
                <div :style="{ height: mapContainerHeight }">
                    <v-row class="fill-height" style="max-height: 100%">
                        <v-col cols="4" style="max-height: 100%">
                            <v-row style="max-height: 100%; overflow-y: scroll">
                                <v-col v-for="program of programs" :key="program.id" cols="12">
                                    <router-link class="mb-4" :to="`/programmes/${program.id}`" style="text-decoration: none;">
                                        <ProgramCard :program="program" />
                                    </router-link>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <l-map ref="map" style="z-index: 1;">
                                <l-tile-layer :url="leaflet.url" :zoom="leaflet.zoom" :center="leaflet.center"></l-tile-layer>
                                <l-marker v-for="program of programsWithCoordinates" :key="program.id" :lat-lng="[program.latitude, program.longitude]">
                                    <l-popup>
                                        <v-card style="height: 100%">
                                            <v-row no-gutters style="height: 100%">
                                                <v-col cols="5" style="height: 100%">
                                                    <v-img :src="getProgramPhoto(program)" height="100%" />
                                                </v-col>

                                                <v-col cols="7">
                                                    <div class="pl-2 pa-4">
                                                        <router-link :to="`/programmes/${program.id}`" style="text-decoration: none;">
                                                            <h4 class="text-uppercase blue--text text--lighten-1">
                                                                {{ program.title }}
                                                            </h4>
                                                        </router-link>
                                                        <span class="grey--text">
                                                            {{ program.city }}
                                                        </span>

                                                        <br>

                                                        <template v-if="program.dispos.length > 0">
                                                            <template v-if="program.minRoomsLabel === program.maxRoomsLabel">
                                                                {{ program.minRoomsLabel }}
                                                            </template>
                                                            <template v-else>
                                                                De {{ program.minRoomsLabel }}
                                                                à {{ program.maxRoomsLabel }}
                                                            </template>

                                                            <br>

                                                            <template v-if="program.minPrice === program.maxPrice && program.minPrice !== 0">
                                                                A partir de <span class="white-space-no-wrap"> {{ program.minPrice | toCurrencyString() }} </span>
                                                            </template>
                                                            <template v-else-if="program.minPrice !== 0 && program.maxPrice !== 0">
                                                                De <span class="white-space-no-wrap"> {{ program.minPrice | toCurrencyString() }} </span>
                                                                à <span class="white-space-no-wrap"> {{ program.maxPrice | toCurrencyString() }} </span>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            Aucun lot disponible
                                                        </template>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </l-popup>
                                </l-marker>
                            </l-map>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-fade-transition>
    </div>
</template>

<script>
import ProgramCard from '../components/programs/ProgramCard.vue';
import DatePickerMenu from '../components/widgets/DatePickerMenu.vue';
import ProductTypeSelect from '../components/widgets/ProductTypeSelect.vue';
import ProductRoomsSelect from '../components/widgets/ProductRoomsSelect.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import TypeResidenceSelect from '../components/widgets/TypeResidenceSelect.vue';
import ProgramLocationSelect from '../components/widgets/ProgramLocationSelect.vue';
import ProgramFiscaliteSelect from '../components/widgets/ProgramFiscaliteSelect.vue';

export default {
    name: 'Programs',

    components: {
        ProgramCard,
        DatePickerMenu,
        ProductTypeSelect,
        ProductRoomsSelect,
        ProgramAutocomplete,
        TypeResidenceSelect,
        ProgramLocationSelect,
        ProgramFiscaliteSelect
    },

    data: () => ({
        view: '',
        mapContainerHeight: '0px',

        leaflet: {
            url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
        },

        displayAdvanceSearch: false,
        order: 'title',
        by: 'asc',
        programSortItems: [
            { text: 'Nom', value: 'title' },
            { text: 'Ville', value: 'city' },
            { text: 'Code postal', value: 'zip' }
        ],

        page: 1,
        quickSearch: '',
        programs: [],
        programsCount: 0,
        totalPages: 1,
        searchDeliveryDate: null,
        menu: null,
        places: [],

        roomsFilter: [],
        typesFilter: [],
        programsFilter: [],
        locationsFilter: [],
        fiscalitesFilter: [],
        productTypesFilter: [],
        productRoomsFilter: [],
        residenceTypesFilter: [],
        productBudgetFilter: [0, 1000],

        beforeDeliveryDateFilter: null,

        disableNavigation: false,
        advanceSearchDataFetched: false
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                limit: 40,
                include: 'photos,dispos',
                orderBy: `${this.order}:${this.by}`,
                archived: 0,
                partner: 1
            };
            if (this.view === 'map') {
                query.limit = this.getConfig('programs.map_list_limit');
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (Array.isArray(this.locationsFilter) && this.locationsFilter.length > 0) {
                query.locations = this.locationsFilter.join(',');
            }
            if (Array.isArray(this.fiscalitesFilter) && this.fiscalitesFilter.length > 0) {
                query.fiscalites = this.fiscalitesFilter.join(',');
            }
            if (Array.isArray(this.productTypesFilter) && this.productTypesFilter.length > 0) {
                query.productTypes = this.productTypesFilter.join(',');
            }
            if (Array.isArray(this.productRoomsFilter) && this.productRoomsFilter.length > 0) {
                query.productRooms = this.productRoomsFilter.join(',');
            }
            if (Array.isArray(this.residenceTypesFilter) && this.residenceTypesFilter.length > 0) {
                query.residenceTypes = this.residenceTypesFilter.join(',');
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.productBudgetFilter) && this.productBudgetFilter.length > 0 && (this.productBudgetFilter[0] !== 0 || this.productBudgetFilter[1] !== 1000)) {
                const min = this.productBudgetFilter[0] === 0 ? '' : this.productBudgetFilter[0] * 1000;
                const max = this.productBudgetFilter[1] === 1000 ? '' : this.productBudgetFilter[1] * 1000;
                query.productBudget = `${min}:${max}`;
            }
            if (this.beforeDeliveryDateFilter) {
                query.beforeDeliveryDate = this.beforeDeliveryDateFilter;
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (Array.isArray(this.locationsFilter) && this.locationsFilter.length > 0) {
                query.locations = this.locationsFilter.join(',');
            }
            if (Array.isArray(this.fiscalitesFilter) && this.fiscalitesFilter.length > 0) {
                query.fiscalites = this.fiscalitesFilter.join(',');
            }
            if (this.beforeDeliveryDateFilter) {
                query.beforeDeliveryDate = this.beforeDeliveryDateFilter;
            }
            if (Array.isArray(this.productTypesFilter) && this.productTypesFilter.length > 0) {
                query.productTypes = this.productTypesFilter.join(',');
            }
            if (Array.isArray(this.productRoomsFilter) && this.productRoomsFilter.length > 0) {
                query.productRooms = this.productRoomsFilter.join(',');
            }
            if (Array.isArray(this.residenceTypesFilter) && this.residenceTypesFilter.length > 0) {
                query.residenceTypes = this.residenceTypesFilter.join(',');
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.productBudgetFilter) && this.productBudgetFilter.length > 0 && (this.productBudgetFilter[0] !== 0 || this.productBudgetFilter[1] !== 1000)) {
                const min = this.productBudgetFilter[0] === 0 ? '' : this.productBudgetFilter[0] * 1000;
                const max = this.productBudgetFilter[1] === 1000 ? '' : this.productBudgetFilter[1] * 1000;
                query.productBudget = `${min}:${max}`;
            }
            this.beforeDeliveryDateFilter = query.beforeDeliveryDate || null;
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.quickSearch = query.quickSearch || null;
            this.locationsFilter = query.locations ? query.locations.split(',').map((t) => t) : [];
            this.fiscalitesFilter = query.fiscalites ? query.fiscalites.split(',').map((t) => t) : [];
            this.productTypesFilter = query.productTypes ? query.productTypes.split(',').map((t) => parseInt(t)) : [];
            this.productRoomsFilter = query.productRooms ? query.productRooms.split(',').map((p) => parseInt(p)) : [];
            this.residenceTypesFilter = query.residenceTypes ? query.residenceTypes.split(',') : [];
            this.programsFilter = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];

            let [min, max] = query.productBudget ? query.productBudget.split(':').map((t) => parseInt(t)) : [0, 1000000];
            min = isNaN(min) ? 0 : min / 1000;
            max = isNaN(max) ? 1000 : max / 1000;
            this.productBudgetFilter = [min, max];
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchPrograms(push, resetPage = true) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);
                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { programs, count, totalPages } = await this.repos.programs.getPrograms(query);
                this.programs = programs;
                this.programsCount = count;
                this.totalPages = totalPages;

                this.centerMapOnPrograms();
            } catch (err) {
                console.error(err);
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        centerMapOnPrograms() {
            if (this.$refs.map) {
                this.$refs.map.mapObject.invalidateSize();
                if (this.programsWithCoordinates.length > 0) {
                    this.$refs.map.mapObject.fitBounds(
                        this.programsWithCoordinates.map((p) => [p.latitude, p.longitude])
                    );
                }
            }
        },

        async fetchPlaces() {
            try {
                const { places } = await this.repos.places.getPlaces();
                this.places = places;
            } catch (err) {
                console.error(err);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchPrograms(true);
        },

        computeMapHeight() {
            if (this.$refs.searchContainer) {
                const rect = this.$refs.searchContainer.getBoundingClientRect();
                const rect2 = document.querySelector('#footer').getBoundingClientRect();
                const height = window.innerHeight - (rect.bottom + rect2.height) - 15;
                this.mapContainerHeight = `${height}px`;
                setTimeout(() => {
                    this.$refs.map.mapObject.invalidateSize();
                }, 0);
            }
        },

        getProgramPhoto(program) {
            if (Array.isArray(program.photos) && program.photos.length > 0) {
                return program.photos[0].info.url;
            }
            return 'https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691';
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                this.parseQuery(to.query);
                this.fetchPrograms(false);
            }
        },

        displayAdvanceSearch() {
            if (this.displayAdvanceSearch && !this.advanceSearchDataFetched) {
                this.advanceSearchDataFetched = true;
                this.fetchPlaces();
                this.$store.dispatch('products/fetchProductsSummary');
            }
            setTimeout(() => {
                this.computeMapHeight();
                this.centerMapOnPrograms();
            }, 300);
        },

        view() {
            this.$nextTick(() => {
                if (this.$refs.map) {
                    // center map on france by default
                    this.$refs.map.mapObject.setView([46.8534, 2.3488], 6);

                    this.computeMapHeight();
                    window.addEventListener('resize', () => {
                        this.computeMapHeight();
                    });
                }
            });
        }
    },

    computed: {
        programsWithCoordinates() {
            return this.programs.filter((p) => p.latitude && p.longitude);
        }
    },

    created() {
        this.view = this.getConfig('programs.default_view', 'cards');
        this.parseQuery(this.$route.query);
        this.fetchPrograms(false);
        this.$store.dispatch('parameters/fetchParameters', ['PRG_TYPERESIDENCE']);
    }
};
</script>

<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>