<template>
    <v-hover v-slot="{ hover }">
        <v-card>
            <v-img :src="getProgramPhoto(program)" height="250px">
                <div class="d-flex flex-column fill-height">
                    <v-row v-show="!hover">
                        <v-col cols="12" class="pt-5 pl-5">
                            <v-chip v-if="getConfig('programs.display_actable', false) && program.saleStatus !== 1" class="mr-2" color="green" dark small label>
                                Actable
                            </v-chip>

                            <v-chip v-if="program.reducedVat && (program.reducedVat.length > 1 || program.reducedVat[0] !== '20%')" color="program-chip" dark label small>
                                <template v-if="program.type === 1 && program.reducedVat[0] === '0%'">
                                    Tva sur marge
                                </template>
                                <template v-else>
                                    Tva réduite
                                </template>
                            </v-chip>
                        </v-col>
                    </v-row>

                    <v-row no-gutters align="end" class="fill-height">
                        <v-col cols="12" :class="{ 'fill-height': hover }">
                            <v-sheet class="pa-2 fill-height" color="rgba(0, 0, 0, 0.7)" style="width: 100%">
                                <h5 class="program-title text-uppercase white--text">
                                    {{ program.title }}
                                </h5>

                                <span class="white--text text--lighten-1 caption">
                                    {{ program.city }}
                                    <span v-if="program.zip ">
                                        ({{ program.zip }})
                                    </span>

                                    <template v-if="program.title && getConfig('programs.display_delivery_date', true) && program.deliveryDate">
                                        -
                                    </template>

                                    <span v-if="getConfig('programs.display_delivery_date', true) && program.deliveryDate">
                                        {{ program.deliveryDateType | toDeliveryDateType() }} {{ program.deliveryDate | toYearDate() }}
                                    </span>
                                </span>

                                <div v-show="hover" class="mt-4">
                                    <v-chip v-for="dispo of program.dispos" color="program-chip" :key="dispo.type + '-' + dispo.rooms" small label dark class="mr-2 mb-2">
                                        <v-chip color="white" class="black--text px-1 mr-2" label x-small>
                                            {{ dispo.count }}
                                        </v-chip>
                                        {{ dispo.typeLabel }} {{ dispo.roomsLabel }}
                                    </v-chip>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
            </v-img>

            <v-card-text class="grey lighten-3">
                <v-chip v-if="getAvailableProductsRange(program, false)" class="mr-2 mb-2" color="program-chip-lighten" dark label small>
                    <v-icon left> fas fa-building </v-icon>
                    {{ getAvailableProductsRange(program, false) }}
                </v-chip>

                <template v-if="program.dispos.length > 0 && program.minPrice > 0">
                    <v-chip class="mr-2 mb-2" color="program-chip-lighten" dark label small>
                        <v-icon left> fas fa-euro-sign </v-icon>
                        A partir de {{ program.minPrice | toCurrencyString() }}
                    </v-chip>
                </template>

                <v-chip class="mr-2 mb-2" color="program-chip-lighten" v-if="program.feeAmount || program.feePercent" dark label small>
                    Taux de rémunération de
                    <template v-if="program.feeAmount">
                        {{ program.feeAmount | round(1) }} €
                    </template>
                    <template v-if="program.feePercent">
                        {{ program.feePercent | round(1) }} %
                    </template>
                </v-chip>

                <br>

                <v-chip v-if="program.residencetype" small label color="program-chip" dark class="mr-2 mb-2">
                    {{ program.residencetype }}
                </v-chip>

                <v-chip v-for="fiscalite of program.fiscalite" :key="fiscalite.key" small label color="program-chip" dark class="mr-2 mb-2">
                    {{ fiscalite.label }}
                </v-chip>
            </v-card-text>

            <div v-if="program.translation[getConfig('programs.translation_promote_code', 'promo_1')]" class="ribbon ribbon-top-right">
                <span class="red darken-3">
                    A la une
                </span>
            </div>
        </v-card>
    </v-hover>
</template>

<script>
import programsMixin from '../../mixins/programs';

export default {
    mixins: [programsMixin],

    props: {
        program: { type: Object, required: true }
    },

    methods: {
        getProgramPhoto(program) {
            if (Array.isArray(program.photos) && program.photos.length > 0) {
                return program.photos[0].info.url;
            }
            return 'https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691';
        }
    }
};
</script>